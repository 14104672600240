@import "../base/colors";
@import "../base/constants";
@import "../mixins/breakpoints";

$purchase-price-font: icomoon,OpenSans,sans-serif;
#epic-marketplace, .store-page-asset {
    .btn {
        margin: 0 auto; }
    //Fix for russian currency
    .price, .asset-price, .save-discount, .base-price {
        font-family: icomoon, $font-family; } }
.asset-price .actual-price {
    font-family: $purchase-price-font; }
#purchase-app {
    .origin-price, .price-value, .current-price {
        font-family: $purchase-price-font; } }

.store-page-asset {
    z-index: 11;
    @extend .page-asset;
    @extend .page-asset-store;
    .asset .info {
        ul {
            li {
                height: inherit;
                &.categories {
                    &:hover {
                        .categories-full-view {
                            display: block; } }
                    span {
                        position: absolute;
                        margin-top: 2px; }
                    ul {
                        display: inline-block;
                        height: auto;
                        vertical-align: bottom;
                        overflow: hidden;
                        .mock-ellipsis-container {
                            .mock-ellipsis-item-helper:not(:last-child) {
                                display: none; } }
                        &.mock-text-ellipsis {
                            width: calc(100% - 100px);
                            .mock-ellipsis-item-cat {
                                width: 100%;
                                display: inline-block;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                vertical-align: middle; } }
                        &.categories-full-view {
                            border: 0;
                            display: none;
                            position: absolute;
                            bottom: 0;
                            background-color: $card-bg-hover;
                            width: auto;
                            white-space: nowrap;
                            z-index: 999;
                            border: none; }
                        & > li a {
                            height: 1em;
                            color: $accent-color; } } }

                &.rating {
                    float: left;
                    width: 8em; } } } }
    header ul li ul {
        display: none;
        &:hover {
            display: block; } } }

