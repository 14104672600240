$unicode-ext: unquote("U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF");
$unicode-regular: unquote("U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000");
$unicode-japanese: unquote("U+3000-303F, U+3040-309F, U+30a0-30FF, U+FF00-FFEF, U+4E00-9FAF");

/* latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: local("Lato Light"), local("Lato-Light"), url("./fonts/lato/LatoLightExt.woff2") format("woff2");
    unicode-range: $unicode-ext; }


/* latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: local("Lato Light"), local("Lato-Light"), url("./fonts/lato/LatoLight.woff2") format("woff2");
    unicode-range: $unicode-regular; }


/* latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local("Lato Regular"), local("Lato-Regular"), url("./fonts/lato/LatoRegularExt.woff2") format("woff2");
    unicode-range: $unicode-ext; }


/* latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local("Lato Regular"), local("Lato-Regular"), url("./fonts/lato/LatoRegular.woff2") format("woff2");
    unicode-range: $unicode-regular; }


/* latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local("Lato Bold"), local("Lato-Bold"), url("./fonts/lato/LatoBoldExt.woff2") format("woff2");
    unicode-range: $unicode-ext; }


/* latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local("Lato Bold"), local("Lato-Bold"), url("./fonts/lato/LatoBold.woff2") format("woff2");
    unicode-range: $unicode-regular; }


/* Japanese */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: local("GenEiGothicPro Light"), local("GenEiGothicPro-Light"), url("./fonts/genEiGothic/GenEiGothicPro-Light.otf") format("opentype");
    unicode-range: $unicode-japanese; }


/* Japanese */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local("GenEiGothicPro Light"), local("GenEiGothicPro-Light"), url("./fonts/genEiGothic/GenEiGothicPro-Light.otf") format("opentype");
    unicode-range: $unicode-japanese; }


/* Japanese */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local("GenEiGothicPro Regular"), local("GenEiGothicPro-Regular"), url("./fonts/genEiGothic/GenEiGothicPro-Regular.otf") format("opentype");
    unicode-range: $unicode-japanese; }


@font-face {
    font-family: 'BrutalType';
    src: url("./fonts/brutal/BrutalType-Regular/BrutalType-Regular.eot");
    src: url("./fonts/brutal/BrutalType-Regular/BrutalType-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/brutal/BrutalType-Regular/BrutalType-Regular.woff") format("woff"),  url('./fonts/brutal/BrutalType-Regular/BrutalType-Regular.svg#BrutalType') format('svg');
    font-weight: 400;
    font-style: normal; }

@font-face {
    font-family: 'BrutalTypeBold';
    src: url("./fonts/brutal/BrutalType-Bold/BrutalType-Bold.eot");
    src: url("./fonts/brutal/BrutalType-Bold/BrutalType-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/brutal/BrutalType-Bold/BrutalType-Bold.woff") format("woff"), url('./fonts/brutal/BrutalType-Bold/BrutalType-Bold.svg#BrutalType-Bold') format('svg');
    font-weight: 600;
    font-style: normal; }

@font-face {
    font-family: 'BrutalTypeMedium';
    src: url("./fonts/brutal/BrutalType-Medium/BrutalType-Medium.eot");
    src: url("./fonts/brutal/BrutalType-Medium/BrutalType-Medium.eot?#iefix") format("embedded-opentype"), url("./fonts/brutal/BrutalType-Medium/BrutalType-Medium.woff") format("woff"), url('./fonts/brutal/BrutalType-Medium/BrutalType-Medium.svg#BrutalType-Medium') format('svg');
    font-weight: 500;
    font-style: normal; }

@font-face {
    font-family: 'BrutalTypeLight';
    src: url("./fonts/brutal/BrutalType-ExtraLight/BrutalType-ExtraLight.eot");
    src: url("./fonts/brutal/BrutalType-ExtraLight/BrutalType-ExtraLight.eot?#iefix") format("embedded-opentype"), url("./fonts/brutal/BrutalType-ExtraLight/BrutalType-ExtraLight.woff") format("woff"), url('./fonts/brutal/BrutalType-ExtraLight/BrutalType-ExtraLight.svg#BrutalType-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal; }
@font-face {
    font-family: 'eurostile';
    font-weight: normal;
    font-style: normal;
    src: url('./fonts/eurostile-webfont.eot?#iefix') format('embedded-opentype');
    src: url('./fonts/eurostile-webfont.woff') format('woff'), url('./fonts/eurostile-webfont.ttf') format('truetype'), url('./fonts/eurostile-webfont.svg#eurostileregular') format('svg'); }

@font-face {
    font-family: 'eurostile';
    font-weight: bold;
    font-style: normal;
    src: url('./fonts/eurostile-bold-webfont.eot?#iefix') format('embedded-opentype');
    src: url('./fonts/eurostile-bold-webfont.woff') format('woff'), url('./fonts/eurostile-bold-webfont.ttf') format('truetype'), url('./fonts/eurostile-bold-webfont.svg#eurostilebold') format('svg'); }

@font-face {
    font-family: 'Nanum Gothic';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/NanumGothic-Regular.eot?#iefix') format('embedded-opentype');
    src: url('./fonts/NanumGothic-Regular.woff2') format('woff2'), url('./fonts/NanumGothic-Regular.woff') format('woff'), url('./fonts/NanumGothic-Regular.ttf') format('truetype'); }

@font-face {
    font-family: 'Nanum Gothic';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/NanumGothic-Bold.eot?#iefix') format('embedded-opentype');
    src: url('./fonts/NanumGothic-Bold.woff2') format('woff2'), url('./fonts/NanumGothic-Bold.woff') format('woff'), url('./fonts/NanumGothic-Bold.ttf') format('truetype'); }

@font-face {
    font-family: 'icomoon';
    font-weight: normal;
    font-style: normal;
    unicode-range: U+20bd, U+e0??, U+e9??, U+ea??;
    src: url('./fonts/icon/icomoon.eot?#iefix') format('embedded-opentype');
    src: url('./fonts/icon/icomoon.woff?a14w0f') format('woff'), url('./fonts/icon/icomoon.ttf?a14w0f') format('truetype'), url('./fonts/icon/icomoon.svg?a14w0f#icomoon') format('svg'); }

.fa-hololens2,
.fa-hololens,
[class^="icon-"],
[class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.fa-hololens2:before,
.fa-hololens:before,
.icon-hololens:before {
    content: "\e90a"; }


.icon-ruble:before {
    content: "\20bd"; }

.icon-updated:before {
    content: "\e903"; }

.icon-added:before {
    content: "\e904"; }

.icon-feature:before {
    content: "\e905"; }

.icon-trending:before {
    content: "\e906"; }

.icon-wiki:before {
    content: "\e60b"; }

.icon-blog:before {
    content: "\e60c"; }

.icon-education:before {
    content: "\e605"; }

.icon-staffpick:before {
    content: "\e606"; }

.icon-grants:before {
    content: "\e607"; }

.icon-answerhub:before {
    content: "\e608"; }

.icon-blog2:before {
    content: "\e609"; }

.icon-forum:before {
    content: "\e60a"; }

.icon-roadmap:before {
    content: "\e60d"; }

.icon-calendar:before {
    content: "\e60f"; }

.icon-download:before {
    content: "\e900"; }

.icon-read:before {
    content: "\e901"; }

.icon-keyboard-arrow-right:before {
    content: "\e600"; }

.icon-clock:before {
    content: "\e014"; }

.icon-search:before {
    content: "\e036"; }

.icon-head:before {
    content: "\e074"; }

.icon-file:before {
    content: "\e124"; }

.icon-arrow-right:before {
    content: "\e604"; }

.icon-youtube:before {
    content: "\ea98"; }

.icon-watch:before {
    content: "\ea98"; }

.icon-google-plus:before {
    content: "\ea88"; }

.icon-facebook:before {
    content: "\ea8c"; }

.icon-facebook3:before {
    content: "\ea8e"; }

.icon-link-external:before {
    content: "\e601"; }

.icon-twitter:before {
    content: "\e90b"; }

.icon-play:before {
    content: "\e902"; }

.icon-twitch:before {
    content: "\e602"; }

.icon-link:before {
    content: "\e909"; }

.icon-key:before {
    content: "\e98d";
    color: #999; }

.icon-hololens2:before {
    content: "\e90a"; }

i.icon-search {
    visibility: hidden;
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 3em;
    background: rgba(51,51,51,0.7);
    padding: 20px;
    border-radius: 200px;
    font-size: 20px;
    color: #fff;
    line-height: 1;

    &:hover {
        cursor: pointer; } }


