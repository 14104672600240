@function pxtoem($target, $context) {
    @return ($target/$context)+0em; }
@function emtopx($target, $context) {
    @return ($target*$context)+0px; }

@mixin clearfix {
	&:before, &:after {
		content: " ";
		display: table; }

	&:after {
		clear: both; } }

@mixin ellipsis() {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    //-moz-binding: url('ellipsis.xml#ellipsis')
    text-overflow: ellipsis; }

@mixin flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between; }

@mixin calc($property, $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -o-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression}); }

.ellipsis {
    overflow: hidden;
    position: relative; }

.hidden {
    display: none !important;
    visibility: hidden; }

.invisible {
    visibility: hidden; }

.alignleft {
    display: inline;
    float: left; }

.alignright {
    display: inline;
    float: right; }

.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto; }

.txtleft {
    text-align: left; }

.txtright {
    text-align: right; }

[data-tooltip] {
    position: relative;

    &:before, &:after {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: all .15s ease;
        z-index: 1; }

    &:before {
        content: attr(data-tooltip);
        padding: 7px 5px 5px 5px;
        width: 200px;
        border-radius: 3px;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        text-align: center;
        font-size: 0.8em;
        top: 100%;
        left: 50%;
        margin-top: 5px;
        transform: translateX(-50%); }

    &:after {
        border: 8px solid transparent;
        width: 0;
        content: "";
        font-size: 0;
        line-height: 0;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 8px solid #000;
        border-top: none; }

    &:hover:before, &:hover:after {
        visibility: visible;
        opacity: 1;
        transform: translateX(-50%) translateY(5px); } }

