@import "../base/colors";
@import "../base/constants";


$font-size-h5: 14px;
$font-size-h6: 12px;


@mixin primaryLink {
    color: $link !important;
    &:hover, &:focus, &:active {
        color: lighten($link, 10%) !important; } }

@mixin accentLink {
    color: $accent-color !important;
    &:hover, &:focus, &:active {
        color: lighten($accent-color, 10%) !important;
 } }        // background: transparent

@mixin button {
    background-color: $accent-color !important;
    color: $link-color !important;
    &:hover, &:focus, &:active {
        background-color: lighten($accent-color, 20%) !important;
        color: lighten($link-color, 20%) !important; } }


html, body {
    font-family: $font-family !important;
    background-color: #121212 !important;
    color: $text-color;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: text;

    unrealengine-navigation {
        --color-background-default: #000;
        --background-default: #000;
        --color-fill-primary-default: #dca100;
        --color-fill-primary-hover: #ffcd43; }

    unrealengine-footer {
        position: relative;
        z-index: 1; }

    &.ko {
        word-wrap: break-word;
        word-break: keep-all; }


    .epic-marketplace ul {
        color: $text-color; }

    .asset-details-container .asset-acquire-callout {
        .distribution-method-text, .promotional-content-text {
            border-color: $text-color !important; } }

    div.site-content {
        background: #000;

        > div {
            background: #000; }

        .sub-nav-container {
            position: sticky;
            left: calc(50% - 700px);
            width: 100vw;
            z-index: 100;
            padding: 0 1em;
            background-color: $bg-site;
            @media only screen and (max-width: $break-largest) {
                left: 0; }
            @include respond-to-smaller(small-screens) {
                padding: 0 0.5em; }
            .page-header-container {
                max-width: 1340px;
                padding: 1em 0 0;
                margin: auto;
                border-bottom: 1px solid $header-border; }
            .page-header-container > ul > li.active>a {
                border-color: $accent-color; } }
        .enableShoppingCart {
            .shopping-cart {
                outline: none;
                .max-amount-alert {
                    background-color: $accent-color;
                    color: $link-color;
                    &:before {
                        border-bottom-color: $accent-color; } }

                .dropdown-content {
                    max-height: calc(100vh - 70px); }
                .cart-offer-list {
                    height: calc(100vh - 70px - 9em);
                    max-height: 25em; }
                .footer {
                    .price {
                        b {
                            margin-right: 5px;
                            color: $accent-color;
                            text-decoration: line-through; } } }

                .icon .cart-amount {
                    background-color: $dark-accent; }
                .footer-btn-group .cart-close {
                    color: $accent-color!important;
                    border-color: $accent-color!important;
                    &:hover {
                        color: lighten($accent-color, 10%)!important; } } } }

        .store-page-asset {
            font-family: icomoon, $font-family !important;
            z-index: 11 !important;
            position: relative;
            overflow: hidden;
            #sign-in-link {
                background: $accent-color !important;
                color: black !important;
                &:hover {
                    background: lighten($accent-color, 20%) !important; } }
            .accent-color, span.active {
                color: $accent-text; }

            .learn-body {
                .learn-links-container {
                    .learn-links ul li {
                        border-color: #535353 !important;
                        span {
                            color: #a4a4a5 !important; }
                        a:hover {
                            span {
                                color: darken(#fff, 20%) !important; } } } }
                .learn-asset {
                    &:hover {
                        .learn-overlay,
                        .learn-resource {
                            background-color: lighten($tile-color, 3%);
                            .learn-contents-bottom-wrapper {
                                background-color: #3a3a3a; } } }
                    .learn-resource {
                        background-color: $tile-color; }
                    .learn-overlay {
                        background-color: $tile-color;
                        &:hover {
                            background-color: lighten($tile-color, 3%);
                            .learn-contents-bottom-wrapper {
                                background-color: #3a3a3a; } }
                        .learn-card-title {
                            color: #f5f5f5;
                            font-weight: 400;
                            text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.8); }
                        .learn-overlay-text {
                            font-size: 13px;
                            line-height: 22px;
                            color: #8b908e; }
                        &:hover {
                            background: lighten($tile-color, 3%); } }
                    .learn-contents-bottom-wrapper {
                        border-top: 1px solid #535353 !important;
                        .learn-contents-text {
                            color: #f5f5f5; }
                        .learn-contents-icons {
                            i {
                                color: whitesmoke !important; } } } }
                .learn-contents-icons .tech-details-link .tech-details-container .tech-details-text {
                    color: $accent-color !important;
                    &:hover,
                    &:focus,
                    &:active {
                        color: lighten($accent-color, 20%) !important; } } }


            #asset-buy-btn {
                background-color: $accent-color;
                color: $link-color;
                &:hover, &:focus, &:active {
                    background-color: lighten($accent-color, 20%);
                    color: lighten($link-color, 20%); } }
            .no-comment {
                background-color: transparent !important;
                a {
                    background: $accent-color;
                    &:hover, &:focus, &:active {
                        background-color: lighten($accent-color, 20%) !important;
                        color: lighten($link-color, 20%) !important; } } }
            .btn {
                @include button; }

            .header-navigation {
                a {
                    @include primaryLink;
                    color: $global-nav-link!important;
                    &.active {
                        border-color: $accent-color !important; } } }
            .bx-prev,
            .bx-next {
                background-color: $accent-color;
                &:before {
                    color: $link-color; }
                &:hover, &:focus, &:active {
                    background-color: lighten($accent-color, 20%); }
                &.disabled {
                    background-color: #333; } }
            .asset-container {
                .asset {
                    .asset-title a {
                        color: #fff !important; }
                    .asset-status {
                        color: $link-color !important;
                        &--owned {
                            color: #fff !important; }
                        &--featured {
                            background: $accent-color !important; } } } }
            .store-featured-items-container {
                .featured-tile {
                    .featured-text {
                        background: $accent-color;
                        color: $link-color;
                        &:before {
                            border-top: 10px solid $accent-color; } } }
                a {
                    @include primaryLink; } }
            .back-button {
                color: $accent-text;
                span,a {
                    color: $accent-text;
                    &:hover,&:focus, &:visited {
                        background-color: $nav-color;
                        color: $accent-text; } } }

            .sub-nav-container {
                color: $header-color;
                margin-top: 0;
                .page-title {
                    margin-top: 0;
                    .h1 {
                        color: $header-color; } }
                li {
                    ul, &:hover, &:focus, &:active {
                        background-color: $nav-color;
                        li {
                            a {
                                border-color: $nav-color;
                                &:hover, &:focus, &:active {
                                    background-color: $accent-color;
                                    border-color: $accent-color;
                                    color: $accent-text; } } } } } }
            h1, h2, h3, h4, h5, h6 {
                font-weight: 300;
                border-bottom: none;
                color: $header-color;
                padding-bottom: 0; }
            hr {
                border: none;
                border-bottom: 1px solid $header-border;
                height: 0; }
            a {
                @include accentLink; }
            .asset {
                color: $card-color;
                background-color: $card-bg;
                transition: none !important;
                box-shadow: none !important;
                &:hover, &:focus, &:active {
                    background-color: $card-bg-hover;
                    img {
                        opacity: 1; }
                    .icon-search {
                        display: none; } }
                h3 {
                    a {
                        @include primaryLink; } }
                .mock-ellipsis-item-cat {
                    @include accentLink; }
                .asset-price {
                    background-color: $card-price; }
                .info {
                    .categories {
                        .categories-full-view {
                            background-color: $card-bg-hover;
                            top: 2px;
                            max-width: calc(100% - 20px);
                            @media screen and (max-width: 770px) {
                                top: 3px;
                                padding-left: 2px; } } }
                    .details {
                        border-top: 1px solid $header-border; } } }
            #carousel {
                .carousel {
                    background-color: $card-bg; }
                .info {
                    background-color: $accent-color;
                    color: $link-color;
                    h1 {
                        padding-top: 0.4em;
                        padding-bottom: 0.6em; } }
                .bullet.selected {
                    background-color: $accent-color;
                    color: $accent-color; } }
            .category {
                background-color: $nav-color;
                border-color: $nav-color;
                h2 {
                    margin: 0.5em 0; }
                a {
                    color: $accent-text;
                    &:hover, &:focus, &:active {
                        background-color: $accent-color; } } }
            .asset-desc {
                &.tags {
                    a {
                        background-color: #000; } }
                padding: 10px 15px 0 15px !important;
                background: none;
                h2 {
                    font-size: 1.4em; }
                .collapse-chooser span {
                    color: $alt-text;
                    &.active {
                        color: $accent-text; } } }
            .asset-details {
                .asset-detail-full {
                    a {
                        @include accentLink; }
                    .btn {
                        color: $accent-text;
                        text-transform: none;
                        &:hover, &:active, &:focus {
                            color: $accent-text; } }
                    .compatibility .fa {
                        color: $highlight-text; } }
                .asset-acquire-callout {
                    .progress {
                        border-radius: 1em;
                        background-color: $page-bg;
                        .progress-bar {
                            background-color: $accent-color; } }

                    .dropdown-menu {
                        padding: 0;
                        min-width: 100%;
                        background-color: $card-bg;
                        text-align: center;
                        -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
                        -moz-box-shadow: 0 6px 12px rgba(0,0,0,.175);
                        box-shadow: 0 6px 12px rgba(0,0,0,.175);
                        &:hover, &:focus {
                            cursor: pointer;
                            outline: none; }
                        li {
                            padding: 0.5em 1em;
                            background-color: $card-bg;
                            color: $accent-text;
                            &:hover {
                                background-color: #4c4c4e;
                                color: $link-color; } } }
                    .fa {
                        color: $highlight-text;
                        &.fa-caret-down {
                            color: $link-color; } }
                    .share-links .fa {
                        @include accentLink; } } }
            .pagination {
                a {
                    color: $accent-color;
                    background-color: $bg-site;
                    border: 1px solid $header-border;
                    &:hover {
                        background-color: $tile-color; } }
                .comment-load-more span {
                    background-color: transparent;
                    border: none; }
                li.rc-pagination {
                    &-item-link:after {
                        background-color: transparent; }
                    &-item-active a {
                        color: $accent-text!important;
                        background-color:  #2A2A2A!important;
                        border-color: $accent-color !important; }
                    &-jump-next, &-jump-prev {
                        &:after {
                            background-color: $bg-site;
                            border-color: $header-border;
                            color: $accent-color; }
                        &:hover {
                            &:after {
                                background-color: $tile-color;
                                color: $accent-hover-light!important; } } } }
                .rc-select {
                    .rc-select-selection-search-input {
                        border: 1px solid $header-border;
                        background-color: $bg-site; }
                    .rc-select-dropdown {
                        border: 1px solid $header-border;
                        background-color: $bg-site;
                        .rc-select-item-option-active {
                            background-color: $tile-color; } } } }



            .asset-acquire-callout {
                background-color: $nav-color;
                margin: 20px 0;
                @media (min-width: 1024px) {
                    margin-bottom: 4px; }
                .asset-acquire-right {
                    .label {
                        color: $accent-text; } } }
            .asset-comments {
                padding: 10px 15px 0 15px !important; }
            .comment-chars {
                color: $error;
                text-align: left; }
            .asset-comments .add-comment #btn-enter {
                margin: 0 auto; }
            .textile-container .textile-head {
                li {
                    padding: 0 15px 2px;
                    border-radius: 5px;
                    margin-right: 6px;
                    height: 30px;
                    color: $link-color !important;
                    &:hover, &:focus, &:active {
                        background-color: lighten($accent-color, 10%) !important;
                        color: lighten($link-color, 20%) !important; } } }
            #asset-download-progress {
                .btn-group {
                    .btn {
                        i {
                            color: $nav-color; } } } }

            .asset-search {
                ul.autocomplete {
                    color: $nav-color;
                    li {
                        &:hover, &:focus, &:active {
                            background-color: lighten($alt-text, 15%); }
                        &.selected {
                            background-color: $accent-color; } } } }
            .asset-list-sort, .filter-dropdown {
                title {
                    font-size: 1em; } }

            .filter-section {
                color: $accent-text;
                background-color: $nav-color;
                @include respond-to-smaller(small-screens) {
                    background-color: #262626ed; }
                .current-sort, .filter-icon {
                    &:hover, &:active, &:focus {
                        background-color: $nav-color;
                        color: $accent-text; } }
                .filter-dropdown {
                    li, .show-all-versions {
                        background-color: $color-link;
                        &:hover, &:active, &:focus {
                            background-color: lighten($color-link, 8%); }
                        &.selected {
                            background-color: $accent-color;
                            color: $accent-text; } }

                    .price-range {
                        .custom-range {
                            span {
                                &:first-child {
                                    background-color: lighten($color-link, 8%); }
                                &.selected {
                                    background-color: $accent-color;
                                    color: $accent-text; } }
                            input {
                                color: #fff;
                                &.error {
                                    color: $danger; } } } } }

                .filter-amount {
                    background-color: $dark-accent; }
                .reset-filter, .confirm-filter {
                    border-color: $accent-color; }
                .btn.reset-filter {
                    color: $accent-color;
                    background-color: transparent;
                    &:hover , &:active, &:focus {
                        background-color: $accent-color;
                        color: $carousel-accent-color; } } }

            .assets-block.marketplace-home-free {
                background-color: #343434 !important; } } }

    .comment-rating-box {
        .loading-svg {
            background: url("../images/loading-bars-launcher.svg") !important;
            background-size: contain !important; }
        .comment-date {
            color: #7b7b7b !important; }
        .no-comment {
            background-color: $accent-color;
            color: $carousel-accent-color;
            a {
                color: $carousel-accent-color; } }
        .comments {
            a {
                @include accentLink; }
            .loading-comments {
                clear: both; }
            .username {
                color: #7b7b7b !important;
                font-weight: normal !important;
                &.accent-color {
                    color: $accent-color !important; } }
            .sort-box {
                display: inline;
                a {
                    color: $accent-color;
                    &:hover,
                    &:focus,
                    &:active {
                        color: lighten($accent-color, 20%) !important; }
                    &.active {
                        color: $accent-text !important;
                        &:hover, &:focus, &:active {
                            color: $accent-text !important; }
                        &:before {
                            border: none; } } } }
            .comment-vote {
                i {
                    color: $alt-text; }
                .vote-highLight {
                    i {
                        color: $accent-color; } } }
            .no-comment {
                a {
                    color: $nav-color;
                    &:hover {
                        color: darken($nav-color, 20%); } } }

            .user-moderation-status {
                background-color: #906900;
                padding: 1.5em;
                p {
                    margin: 0; } }

            .comments-container {
                & > h4 {
                    padding: 1em;
                    margin-bottom: 1em; } } }
        .enter-comment-button {
            border: none !important; } }

    .textile-container-view {
        background-color: $bg-site;
        &::before {
            border-top: 15px solid $tile-color; }
        .textile-preview {
            font-weight: 100; } }
    .textile-container {
        font-family: $font-family;
        background: $nav-color;
        padding-bottom: 20px;
        .textile-head {
            li {
                font-family: $font-family;
                background-color: $accent-color;
                border-color: $accent-color !important;
                color: $carousel-accent-color;
                &.selected {
                    border-color: darken($accent-color, 20%) !important;
                    background-color: darken($accent-color, 20%);
                    color: $carousel-accent-color!important;
                    cursor: default;
                    &:hover, &:focus, &:active {
                        color: $carousel-accent-color!important;
                        border-color: darken($accent-color, 20%) !important;
                        background-color: darken($accent-color, 20%)!important; } } } }
        textarea {
            max-width: 100% !important;
            background-color: $nav-color;
            border-color: $header-border !important;
            color: $accent-text !important; }
        .textile-preview, .textile-help {
            background-color: $nav-color;
            border-color: $header-border !important;
            color: $carousel-accent-color !important; } }
    .validMessage, .invalidMessage {
        color: $error;
        display: inline-block; }
    .btn {
        margin: 0 auto; }
    .comment-window {
        .textile-preview {
            p {
                color: $accent-text; } } }

    .edit-modal, .reply-edit-modal, .delete-modal {
        div, .cw-title {
            background-color: $nav-color !important; }
        .comment-edit-chars.invalidMessage {
            color: $error!important; }
        .cw-close {
            color: $accent-text;
            &:hover, &:focus, &:active {
                color: darken($accent-text, 10%); } }
        .enter-comment-button, a.btn-primary, button.btn {
            background-color: $accent-color !important;
            color: $color-link !important; } }
    .delete-modal p {
        color: $color-link; }

    .tab:hover {
        background: $nav-color !important; }
    .tab__btn {
        color: $accent-text; }
    .tab--active {
        border-color: $accent-color !important; }
    .tabList {
        border-color: $header-border !important; }
    .mktBtn--primary {
        background: $accent-color !important;
        color: $color-link !important;
        &:hover {
            background: $accent-hover !important; } }

    .enterprise-modal_dialog {
        &.eula-modal_dialog {
            padding: 55px 40px 20px 40px;
            background-color: $white;
            color: $asset-description-text;
            width: 700px;
            max-width: 700px;
            height: 90%;
            display: flex;
            flex-direction: column;
            @media (max-width: 900px) {
                max-width: 100%;
                width: 100%; }
            @media (max-width: 700px) {
                padding: 20px; }
            .header {
                border-bottom: solid 0 !important;
                color: $default-heading;
                text-align: center;
                font-size: 100%; } } }
    .asset-detail-rating, .asset-reviews, .asset-questions-container, .upload-status, .enterprise-modal_dialog .footer {
        .loading {
            background-image: url("../images/loading-bars-launcher.svg") !important; } }

    .select-div {
        border: 1px solid $header-border !important;
        .select-options {
            border: 1px solid $header-border;
            background-color: $bg-site !important;
            color: $accent-text !important;
            li {
                &:hover, &:active, &:focus, &.selected {
                    background-color: $tile-color !important; } } } }

    .image-list .file-upload {
        color: $accent-color !important;
        border-color: $accent-color !important; }


    .rating-board__pop {
        &__container {
            background: $nav-color;
            border-color: $nav-border; }
        &__content__percent {
            background: $nav-border !important;
            >div {
                background-color: $accent-color !important; } } }

    .asset-detail-rating__summary__sep {
        background-color: $header-border !important; }

    .review-item {
        border-color: $header-border !important;
        &__title h4 {
            font-weight: 700 !important; }
        &__best:not(.review-item__best--isBest) {
            background-color: $accent-color !important;
            .fa {
                color: $link-color !important;
                background-color: $accent-color !important; } }
        &__content {
            &.self_warning {
                font-weight: 600; } }
        &__tools li {
            .fa, span {
                color: $accent-color !important;
                &:hover {
                    color: $accent-hover-light !important; } }
            &.button-show_anyway {
                color: $accent-color; } }
        &__helpful .fa-thumbs-up {
            color: $accent-color !important;
            background: transparent !important;
            &:hover {
                color: $accent-hover-light !important; }
            &.disabled {
                color: $disabled !important; } }
        &__replies__more span {
            color: $accent-color !important;
            &:hover {
                color: $accent-hover !important; } } }

    .rich-text-content {
        a {
            @include accentLink; } }

    .beta {
        color: $accent-color !important; } }

[class*="enterprise-modal_"] {
    background-color: #262626;
    color: $accent-text;
    .header {
        border-bottom: 1px solid #3b3c3d !important;
        color: #f5f5f5; }
    .text-editor {
        .rich-text-content {
            p {
                color: $accent-text; } }
        .ql-container {
            border-color: #3b3c3d !important;
            .ql-editor {
                background-color: #131313 !important;
                border-color: #3b3c3d !important;
                &.ql-snow {
                    border: 1px solid #3b3c3d; } } }
        .ql-toolbar {
            border-color: #3b3c3d !important;
            background-color: #3b3c3d !important;
            color: #f5f5f5; }
        .character-amount {
            color: #f5f5f5; } }
    .summary-input__input {
        background-color: #131313;
        border: solid 1px #3b3c3d;
        color: #f5f5f5; } }
