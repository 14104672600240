@import "../mixins/_breakpoints";
@import "../base/colors";
@import "../layout/grid";

.store-page-asset {
    h1, h2, h3, h4, h5, h6 {
        color: $header-color;
        text-transform: none; }

    a, button {
        outline: none !important;
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        box-shadow: none !important;
        &:hover, &:active, &:focus {
            outline: none !important;
            -webkit-box-shadow: none !important;
            -moz-box-shadow: none !important;
            box-shadow: none !important; } }

    a:not(.btn) {
        color: $accent-color;
        text-decoration: none;
        &:hover, &:active, &:focus {
            color: darken($accent-color, 20%); } }

    #carousel, .carousel, .defaultimg {
        max-height: 30.5em !important;
        @include respond-to(handhelds) {
            min-height: 20em !important; }

        @include respond-to(small-screens) {
            min-height: 24em !important; }

        @include respond-to-larger(small-screens) {
            min-height: 30.5em !important; } } }

.page-asset {
    margin: 0 auto;
    > div {
        > aside {
            padding-top: 30px;
            @include grid(2);
            h1 {
                text-align: center;
                font-family: $font-family;
                -webkit-font-smoothing: subpixel-antialiased;
                font-size: 19px; }
            > * {
                margin-left: 20px !important; } } } }

.page-asset-store {
    margin: 0 auto;
    .btn {
        background-color: $btn-color;
        &:hover, &:active, &:focus {
            background-color: darken($btn-color, 20%); } }
    .assets-block {
        @include row;
        @include respond-to-smaller(medium-screens) {
            padding: 0 0; }

        overflow: visible;
        &.offset-top {
            margin-top: 1em; }
        + .assets-block {
            margin-top: 2em; }
        &.marketplace-home-free {
            background-color: #E8E3E3;
            padding-top: 2em;
            padding-bottom: 2em;
            @include respond-to(largest-screens) {
                width: 100vw;
                margin-left: calc((100vw - 1400px) / -2);
                padding-left: calc((100vw - 1400px) / 2 + 30px);
                padding-right: calc((100vw - 1400px) / 2 + 30px); } }
        .sub-header {
            @media screen and ( max-width: 420px  ) {
                padding: 0 10px; }
            h1, h2, a, .h2 {
                display: inline; }
            a {
                float: right;
                margin-top: .8em; } } }
    .store-page-asset {
        margin-top: 2em; }
    .no-margin-top {
        margin-top: 0; }
    .carousel-container {
        @include respond-to-smaller(medium-screens) {
            @include grid(12); }

        @include respond-to(wide-screens) {
            @include grid(9); } }
    .categories-list-container {
        @include respond-to(wide-screens) {
            @include grid(3); }

        .store-top-categories {
            h2 {
                color: $category-header; } } }

    .category {
        border-radius: 2px;
        min-height: 30.5em;
        border: 1px solid $category-border;
        background-color: $card-bg;
        single-transition: background-color, 0.1s, ease-out, 0s;
        padding: 0 !important;
        &:hover, &:active, &:focus {
            background-color: $card-bg-hover; }
        @include respond-to-smaller(medium-screens) {
            display: none; }

        h2 {
            font-size: 1.4em;
            font-weight: bold;
            padding: 0.5em;
            margin-bottom: 0;
            border-bottom: 1px solid $category-border; }
        ul {
            li {
                display: block;
                a {
                    color: $link;
                    font-family: $font-family;
                    font-size: 0.8em;
                    -webkit-font-smoothing: subpixel-antialiased;
                    padding: 1em;
                    width: 100%;
                    display: inline-block;
                    &:hover {
                        background-color: $accent-color;
                        color: $accent-text; } } } } }
    .cat-block {
        @include grid(6);
        > header {
            h2 {
                @include heading(h3);
                border-bottom: 2px solid #000; } } } }

.store-categories-container {
    a {
        display: block;
        padding: 0.2em; } }

#categories-container, .store-categories-container {
    .category-container {
        .sub-header {
            @include respond-to(handhelds) {
                padding: 0 0.8em; } } }
    .category-container:not(:first-child) {
        margin-top: 2em; }
    hr {
        margin: 0.5em 0 1em 0; } }

.category-container {
    .sub-header {
        h2 {
            text-transform: capitalize; }
        a {
            color: $accent-color;
            &:hover, &:active, &:focus {
                color: darken($accent-color, 20%); } } } }

.category-navigation {
    font-size: 0.8em;
    width: 10%;
    position: fixed;
    margin-left: 99em;
    top: 8em;
    z-index: 1;
    display: none;
    @include allButLargest {
        display: none !important; }

    .category {
        width: auto;
        min-height: inherit;
        h2 {
            padding: 0.6em 1em;
            a {
                color: $category-header; } }
        ul {
            padding-bottom: 0.5em;
            li {
                &.active {
                    a {
                        border-left: 3px solid $accent-color; } }
                a {
                    padding-left: 1.2em;
                    border-left: 3px solid transparent; } } } } }

.category-container, .profile-assets-container, .asset-details-container, .featured-items-container,
.payment-container, .payment-successful-container, .error-message-container {
    @include row;
    @include clearfix;
    @include respond-to(handhelds) {
        padding: 0; } }
.offset-top {
    margin-top: 2em; }

.category-container, .profile-assets-container, .featured-items-container {
    overflow-y: visible; }

.asset-details-container {
    .asset-content-container {
        @include clearfix; }
    a:not(.btn) {
        color: $accent-color;
        &:hover, &:focus, &:active {
            color: darken($accent-color, 20%); } }
    .bx-controls-direction a.disabled {
        cursor: not-allowed; } }

.billing-accounts-container {
    margin-top: 2em; }

.error-message-container {
    margin-bottom: 2em;
    color: $error;
    font-weight: bold;
    padding: 1em;
    border: 1px solid $error; }

.featured-items-container, .payment-container {
    margin-bottom: 2em;
    .sub-header {
        @media screen and (max-width: $break-small ) {
            padding: 0 10px; }
        h1, h2, .h2 {
            font-size: 1.6em;
            font-weight: bold; }
        @include respond-to(handhelds) {
            h1, h2, .h2 {
                font-size: 1.2em; } } } }


.requested-payment-amount, .billing-account-options {
    @include row;
    padding-left: 0;
    padding-right: 0;
    > * {
        @include grid(6);
        padding-left: 0;
        padding-right: 0;
        @include respond-to(handhelds) {
            @include grid(12);
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 1em; } } }

.asset-details {
    @include clearfix; }

.direct-contact {
    cursor: pointer;
    margin-left: 5px;
    vertical-align: middle;
    display: inline-block;
    width: 20px;
    height: 20px; }

.asset-description-wrapper,
.asset-comments {
    margin-top: 20px;
    padding: 0; }

.asset-description-wrapper {
    padding: 1em 0;
    .learn-body span:first-child {
        border-right: 1px solid $line-color; }

    p {
        @include respond-to(handhelds) {
            font-size: 0.8em; } }

    .technical-details {
        margin-top: 1em;
        h3 {
            font-size: x-large;
            margin-bottom: 20px; }
        .tech-details-link {
            display: inline-block;
            margin-top: 1em;
            .tech-details-container {
                .tech-link-image {
                    font-size: 30px;
                    margin-right: 15px;
                    font-style: normal; }
                .tech-details-text {
                    color: $accent-color;
                    position: relative;
                    bottom: 8px; } } } }

    i {
        font-style: italic; }
    code {
        color: inherit; }

    b, strong {
        font-weight: 700; }

    em {
        font-style: italic; }

    blockquote {
        padding: 10px 20px;
        margin: 0 0 20px;
        font-size: 17.5px;
        border-left: 5px solid #eee; }

    ol, ul {
        display: block;
        -webkit-margin-before: 1em;
        -webkit-margin-after: 1em;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
        -webkit-padding-start: 40px;
        -moz-margin-before: 1em;
        -moz-margin-after: 1em;
        -moz-margin-start: 0px;
        -moz-margin-end: 0px;
        -moz-padding-start: 40px; }

    ol {
        list-style: decimal; }

    ul {
        list-style: disc; } }

.add-payment-method.btn {
    width: 20em;
    margin: 0; }

.vault-btn {
    width: 12em !important; }

.social-share {
    display: none; }

.asset-desc {
    h1 {
        font-size: 1.2em;
        font-weight: bold; }
    h2, h3, h4, h5, h6, .h3 {
        font-size: 1.1em;
        font-weight: bold; }
    .collapse-chooser {
        font-weight: bold;
        font-size: 1.25em;
        @include clearfix;
        span {
            color: $asset-description-text;
            cursor: pointer;
            float: left;
            padding: 0 10px;
            @include respond-to(handhelds) {
                @include grid(6);
                &:first-child {
                    padding: 0 10px 0 0;
                    border-right: 2px solid $asset-description-text; }
                label {
                    float: left; }
                .active {
                    color: $asset-active-description-color;
                    cursor: inherit; }
                .collapse-container {
                    display: none; }
                .active {
                    display: block; } } } }
    &.tags {
        p {
            margin-top: 10px; }
        .tag-item {
            display: inline-block;
            background-color: $tile-color;
            color: $highlight-light-text;
            padding: 0 5px;
            margin-right: 5px;
            margin-bottom: 5px; } } }
.learn-contents {
    margin-top: 20px;
    width: 100%;
    border-top: 1px solid #d1d1d1;
    .learn-contents-icons {
        margin-left: .4em;
        border: none!important; }
    a {
        span {
            color: $accent-color;
            font-size: 1em !important; }
        &:hover {
            span {
                color: darken($accent-color, 20%); } } } }

.profile-details {
    font-family: $font-family;
    -webkit-font-smoothing: subpixel-antialiased;
    .info {
        p {
            padding-top: 10px;
            padding-left: 20px; } } }

.profile-assets {
    background-color: $header-border;
    box-shadow: inset 0 0 10px #777777;
    border-radius: 4px;
    padding: 20px;
    margin-top: 50px;
    @include clearfix;
    h1 {
        span {
            color: $accent-color; } } }

.message-dialog {
    position: relative;
    background: $light-bg;
    width: 40%;
    margin: 0 30% 0 30%;
    padding: 20px;
    border-radius: 14px;
    .mfp-close {
        opacity: 1;
        right: -9px;
        top: -9px;
        background-color: #ffffff; }
    header h1 {
        font-weight: bold;
        text-align: center; }
    form {
        padding-top: 20px;
        .input-control {
            &:focus {
                background: $search-background; }
            width: 100%; }
        .btn {
            margin: 0 20px;
            display: inline-block; }
        .btn-first {
            margin-left: 15%; }
        .btn-last {
            margin-right: 15%; } } }

.sub-header {
    margin: 0;
    &.no-event {
        min-height: 45vh; }
    h1, h2 {
        font-size: 1.8em;
        font-weight: bold; }
    p {
        font-size: 1em; }
    .callout {
        font-weight: bold; } }

.gallery {
    &:hover {
        cursor: pointer; } }

.store-page-asset {
    .order-container {
        margin-top: 2em;
        @include row;
        @include clearfix;
        .price {
            color: $asset-price-color;
            font-weight: bold; }
        .price_save {
            color: $asset-price-color;
            font-size: 12px;
            font-weight: bold; }
        .order-list-price {
            color: $asset-list-price-color;
            text-decoration: line-through; }
        .order-base-price {
            color: $asset-list-price-color; }
        .total {
            font-size: 16px;
            font-weight: bold;
            padding-right: 20px;
            text-align: right; }
        .tax {
            text-align: left; }
        .preview-error span {
            color: $asset-order-error;
            font-weight: bold; }
        .purchase {
            text-align: center;
            .btn {
                width: 100%;
                margin-bottom: 1em;
                @include respond-to(handhelds) {
                    max-width: 100%; } } }
        .billing-account-options {
            margin-bottom: 1em; }

        thead {
            color: $header-color;
            border-bottom: 1px solid $header-border;
            text-align: left; }
        table {
            margin-bottom: 1em;
            th {
                font-weight: bold; }
            td {
                text-align: left;
                border-bottom: 1px solid $header-border;
                &.quantity {
                    @include respond-to(handhelds) {
                        text-align: right; } }
                a {
                    color: #0066c0;
                    font-size: 16px;
                    font-weight: bold;
                    &.btn {
                        color: #fff;
                        margin: 5px auto; } } }
            &.total-info td {
                border-bottom: {
                    width: 0;
                    style: none; } }
            div {
                padding: 10px;
                width: 100%;
                text-align: right; } } } }

.mfp-wrap.mfp-gallery {
    z-index: 100000;
    .mfp-content {
        .mfp-figure {
            border: none;
            .mfp-close {
                right: 0;
                color: white;
                margin-top: 0.35em;
                width: 1em;
                top: 0; }
            .mfp-title {
                background-color: transparent; } }
        .mfp-counter {
            right: 1em; } } }

.mfp-bg.mfp-with-zoom {
    z-index: 99999; }

.page-order-detail {
    margin-top: 2em;
    header {
        background-color: inherit;
        padding-bottom: 0;
        font-size: 1.2em; }
    .tax-table {
        thead {
            border-bottom: none; } }
    div + div {
        margin-top: 1em; }
    table {
        & + div {
            margin-top: 0.5em; }
        tr {
            td {
                padding: 0.5em 0; } } } }

.push-down {
    margin-top: 1em; }

.push-down-lg {
    margin-top: 2em; }

@media (min-width: 900px) and (max-width: 1140px) {
    div.asset-dl-message {
        div.dl-text {
            width: 100% !important;
            text-indent: 55px !important;
            &:before {
                left: 10px !important; } }
        .mac, .pc {
            a.btn {
                width: 90% !important; } } } }

div.asset-dl-message {
    div.pc, div.mac {
        @include clearfix;
        position: relative;
        div.dl-text {
            width: pxtoem(116, 16);
            clear: left;
            display: inline-block;
            float: left;
            text-transform: uppercase;
            font-weight: bold; }
        a.btn {
            margin-top: pxtoem(5, 16);
            margin-left: .75em;
            display: inline-block;
            float: left;
            clear: right;
            font-weight: bold;
            position: relative;
            &:before {
                content: "";
                position: absolute;
                left: pxtoem(40, 16); }
            &.hotLink {
                width: 175px;
                text-indent: pxtoem(33, 16);
                font-size: pxtoem(14, 16); } } }
    div.mac {
        margin-top: pxtoem(10, 16);
        div.dl-text:before {
            content: "";
            position: absolute;
            left: 0; } }
    div.pc {
        div.dl-text:before {
            content: "";
            position: absolute;
            left: 0; } } }

.pswp.top-overlay {
    z-index: 99999; }

#sort-div {
    > div {
        @include respond-to(handhelds) {
            float: none !important; } } }

.loading-spinner-center {
    text-align: center;
    margin: 2em 0;
    img {
        width: 4em;
        height: 4em; } }

.fallback-content {
    img {
        max-width: 100%;
        height: auto; } }
.social-link {
    font-size: 16px;
    margin-left: 12px;
    .glyphicon {
        width: 20px; } }
