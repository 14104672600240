$break-small: 480px;
$break-medium: 770px;
$break-large: 1080px;
$break-xlarge: 1200px;
$break-medium-landscape: 770px;
$break-largest: 1400px;

@mixin respond-to($media) {
    @if $media == handhelds {
        @media only screen and (max-width: $break-small) {
            @content; } }

    @else if $media == small-screens {
        @media only screen and (min-width: $break-small + 1) and (max-width: $break-medium - 1) {
            @content; } }

    @else if $media == medium-screens {
        @media only screen and (min-width: $break-medium + 1) and (max-width: $break-large - 1) {
            @content; } }

    @else if $media == wide-screens {
        @media only screen and (min-width: $break-large) {
            @content; } }

    @else if $media == xlarge-screens {
        @media only screen and (min-width: $break-xlarge) {
            @content; } }
    @else if $media == largest-screens {
        @media only screen and (min-width: $break-largest) {
            @content; } } }

@mixin respond-to-smaller($media) {
    @if $media == handhelds {
        @media only screen and (max-width: $break-small) {
            @content; } }

    @else if $media == small-screens {
        @media only screen and (max-width: $break-medium) {
            @content; } }

    @else if $media == medium-screens {
        @media only screen and (max-width: $break-large - 1) {
            @content; } }

    @else if $media == wide-screens {
        @media only screen and (max-width: $break-large) {
            @content; } }

    @else if $media == xlarge-screens {
        @media only screen and (max-width: $break-xlarge) {
            @content; } } }

@mixin respond-to-larger($media) {
    @if $media == handhelds {
        @media only screen and (min-width: $break-small) {
            @content; } }

    @else if $media == small-screens {
        @media only screen and (min-width: $break-medium) {
            @content; } }

    @else if $media == medium-screens {
        @media only screen and (min-width: $break-large - 1) {
            @content; } }

    @else if $media == wide-screens {
        @media only screen and (min-width: $break-xlarge) {
            @content; } } }

@mixin allButLargest {
    @media only screen and (max-width: $break-largest) {
        @content; } }
