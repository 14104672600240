@import "colors";

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    font-smoothing: antialiased;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

html {
    -ms-overflow-style: scrollbar;
    -webkit-text-size-adjust: 100%;
    background-color: $bg-html;

    body {
        background-color: $bg-site;
        overflow-x: hidden;
        overflow-y: scroll;
        &.noscroll {
            overflow: hidden; } } }

a {
    text-decoration: none;
    pointer-events: all; }

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $header-border;
    margin: 1em 0;
    padding: 0; }

audio, canvas, img, video {
    vertical-align: middle; }

::-webkit-input-placeholder {
    color: #bbb; }

::-moz-placeholder {
    color: #bbb; }

::-moz-placeholder {
    color: #bbb; }

@-ms-viewport {
    width: device-width; }

.clearfix {
    @include clearfix(); }

// fix ue.com styles
.epic-marketplace {
    font-family: $font-family;
    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        line-height: inherit; }
    ul {
        margin: 0; } }

.overflow-y-visible {
    overflow-y: visible!important; }
