$grid-columns : 12;

@function calc-width($max-width, $column) {
	@return $max-width / ($grid-columns / $column); }

//// GRID ROW
@mixin row {
	width: 100%;
	margin: 0 auto;
	padding: 0 30px;
	overflow-y: hidden;

	&:before, &:after {
		content: " ";
		display: table; }

	&:after {
		clear: both; } }

//// GRID COLUMN
@mixin grid($columns, $grid-padding: 10px, $offset:"", $is-collapse:false) {
	//// shared style in all states
	position: relative;
	float: left;
	width: calc-width(100%, $columns);

	@if $is-collapse {
		padding: 0; }
	@else {
		padding-left: $grid-padding;
		padding-right: $grid-padding; }

	@if $offset != "" {
		margin-left: calc-width(100%, $columns) * $offset; } }

//// GRID SOURCE ORDER
@mixin push($columns) {
	right: auto;
	left: calc-width(100%, $columns); }

@mixin pull($columns) {
	left: auto;
	right: calc-width(100%, $columns); }

//// LIST GRID
@mixin grid-list($columns) {
	position: relative;
	width: 100%;
	margin: 0 auto;
	overflow-y: hidden;
	display: block;
	padding: 0;

	&:before, &:after {
		content: " ";
		display: table; }

	&:after {
		clear: both; }

	> li:nth-of-type(#{$columns}n+1) {
		clear: both; }

	> * {
		width: (100% / $columns);
		height: auto;
		float: left;
		padding-left: 0.45em;
		padding-right: 0.45em;
		padding-bottom: 0.9375em; } }


