
$highlight-light-text: #f3f3f3;
$discount-color : #4f802d;
$line-color: #3b3c3d;

$default-heading: #313233;

$header-color: #4c4c4e;
$header-border: #d1d1d1;
$darker-header: #fff;
$darker-text: #efefef;

$link-highlight: #313233;

$light-bg: #f3f3f3;
$darker-bg: #313233;
$darker-bg-border: #e2e3e4;
$error: #af4f4f;

$card-bg: #fff;
$card-invert-bg: #313233;
$card-invert-text: #fff;
$card-overlay-bg: #000;
$card-bg-hover: #fafafa;

$learn-bg: rgba(red(#313233), green(#313233), blue(#313233), 0.9);

$asset-active-description-color: #fff;
$asset-description-text: #313233;
$asset-link-color: #313233;
$asset-list-price-color: #ccc;
$asset-price-color: #b12704;
$asset-order-error: #b12704;
$asset-cover-note-bg: #7cb241;
$asset-cover-note-color-hv: #dfdfdf;
$category-border: $header-border;
$category-header: #313233;

$search-background: #fff;
$search-icon: #ccc;

$comment-text: #313233;

// COLORS
$bg-html: #fff;
$bg-site: #fff;
$color-link: #333;
$color-link-hover: #444;

$danger: #F35F46;
$info: #43B9B8;
$success: #6AC539;
$warning: #ffd324;

// FONTS
$font-family: eurostile, 'Lucida Grande', 'Lucida Sans Unicode', Helvetica, Arial, Verdana, sans-serif;
$alt-font-family: 'Lucida Grande', 'Lucida Sans Unicode', Helvetica, Arial, Verdana, sans-serif;
$eurostile-stack: eurostile;
$source: 'Source Sans Pro', sans-serif;

$shopping-cart-bg: #222;
$shopping-cart-scroll-bg: $shopping-cart-bg;
$shopping-cart-scroll-bg-highlight: $shopping-cart-scroll-bg;
$shopping-cart-scroll-thumb: #5f5f5f;
$shopping-cart-scroll-thumb-highlight: lighten($shopping-cart-scroll-thumb, 10%);

$white: #fff;
$gray: #1e1e1e;
$dark-gray: #151515;
$light-gray: #222;
$active-color: #0087be;
$primary-color: #0aaff1;
$primary-color-hover: #10b4f5;
$red: #D0021B;
$dark-red: #d04b4b;
$yellow: #FCDA42;
$black: #000;
$light-yellow: #FEEDA5;
$green: #28A745;

$text-normal: #dcdcdc;
$text-highlight: #fff;
$text-gray: #999999;
$bg-gray: #ececec;
$border-gray: #dadada;

$light-blue: #0aaff1;
$form-gray: #d2d2d2;
$form-focus-border: $light-blue;
$formGrayText: #7c7c7c;

$gray-text: #8C8C8C;

$box-shadow: 2px 0 5px 0 #d6d6d6;

$disable-text: #dadada;
$disable-hover: #BABABA;

$padding: 3em;

$brutal: 'BrutalType';
$brutal-bold: 'BrutalTypeBold';
$brutal-light: 'BrutalTypeLight';

@mixin heading5-regular($font-size) {
    font-size: $font-size;
    font-family: 'BrutalType'; }

@mixin heading5-medium($font-size) {
    font-size: $font-size;
    font-family: 'BrutalTypeMedium'; }


$page-bg: #1e1e1e;
$link: #f3f3f3;
$global-nav-link: #aaaaae;
$accent-color: #dca100;
$accent-text: whitesmoke;
$accent-hover: #FFCD43;
$accent-hover-light: #ffbf10;
$link-color: black;
$tile-color: #262626;
$nav-color: #262626;
$nav-border: #171717;
$header-border: #3b3c3d;
$header-color: #f3f3f3;
$card-bg: $nav-color;
$card-bg-hover: #2a2a2a;
$card-color: white;
$card-price: #8fbc59;
$alt-text: #9a9a9a;
$alt-color: #0797dc;
$highlight-text: $accent-text;
$header-highlight: $accent-text;
$bg-html: #161719;
$bg-site: #121212;
$color-link: #333333;
$color-link-hover: #444444;
$dark-accent: #e27204;
$carousel-accent-color: black;
$text-color: #f5f5f5;

$danger: #f35f46;
$info: #43b9b8;
$success: #6ac539;
$disabled: #a3a3a3;
$error: #af4f4f;

$card-price: $accent-color;
$btn-color: $accent-color;





