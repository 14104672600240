@import "../mixins/breakpoints";
@import "../base/colors";

//Reset styles for store pages
.store-page-asset {
    line-height: 1.5;
    font-size: 16px;
    min-height: 80vh;

    ol li, ul li {
        margin-bottom: 0; }
    header {
        text-align: left;
        text-transform: none; }

    .shared {
        z-index: 1; }

    .back-button {
        float: left;
        color: $header-color;
        padding-top: 15px;
        margin-right: 15px;
        @media (max-width: 1024px) {
            margin-right: 0;
            padding-top: 7px;

            i {
                font-size: 36px; } }
        > span,
        > a {
            padding: 16px 16px 20px 16px;
            color: $header-color;
            cursor: pointer;
            @media (max-width: 1024px) {
                padding-left: 0; }
            &:hover {
                background-color: $nav-color; }
            span {
                padding-left: 2px;
                @media (max-width: 1024px) {
                    display: none; } } } } }


.store-page-asset, .ReactModalPortal {
    .btn {
        position: relative;
        margin: 0 auto;
        padding: 0;
        display: block;
        width: auto;
        max-width: 20em;
        height: 45px;
        background-color: $accent-color;
        color: $accent-text;
        font-family: $font-family;
        font-size: 1.125em;
        line-height: 47px;
        text-align: center;
        border: none;
        outline: none;
        cursor: pointer;
        -webkit-transition: 0.3s all ease-in;
        -moz-transition: 0.3s all ease-in;
        -o-transition: 0.3s all ease-in;
        transition: 0.3s all ease-in;
        border-radius: 2px;
        &:active, &:focus, &:hover {
            background-color: darken($accent-color, 20%);
            color: $accent-text; } } }


